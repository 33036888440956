<template>
 <v-flex lg12 xs12>
    <v-layout row wrap  v-if="!loaded">
        <v-flex lg12 class="text-center pt-5" >
            <v-progress-circular :size="50" color="primary" indeterminate>
            </v-progress-circular>
             <span class="ml-5">Verificando datos del Sobre de Postulación</span>
        </v-flex>
    </v-layout>
    <component v-else :is="isPostulate?'InfoPostulacion':'NuevaPostulacion'" :postulacion="postulacion"
    @afterCreate="inicialize"
    ></component>
 </v-flex>
</template>
<script>
import {mapState} from 'vuex'
export default {
  components:{
    'NuevaPostulacion':()=>import('@/views/Convocatoria/NuevaPostulacion'),
    'InfoPostulacion':()=>import('@/views/Convocatoria/InfoPostulacion')    
  },
  computed:{
    ...mapState('abogado',{...'isRegistered'})
  },
  data(){
      return{
          loaded:false,
          isPostulate:false,
          postulacion:null
      }
  },
  created(){
    this.inicialize()
  },
  methods:{
    inicialize(){
      this.loaded=false
      this.$http.get(`${this.$apiUrl}abogado-ciudadano/postulacion-convocatoria/${this.$route.params.id}`)
    .then(response=>{
      this.loaded=true
      if(response.data.data.data!==null){
        this.isPostulate=true
        this.postulacion=response.data.data.data
      }else{
        this.isPostulate=false
      }
    }).catch(error=>{
      console.log(error)
      this.$router.push("/convocatorias")
    })
    }
  }
}
</script>